<template>
  <div class="header">
    <div class="header-top">
      <a href="/" class="logo"><img src="..\assets\images\logo-2.jpeg" width="250px" height="100px"></a>
    </div>
    <div class="navbar-container">
      <div class="navbar-right">
        <ul class="menu-items">
          <li><img src="..\assets\images\email.png" /> info@legalaura.com</li>
          <li>
            <a href="https://wa.me/918826526412?text=Hello%20Legalaura">
              <div class="whatsapp_blink">

              </div>
              <div class="wcs_button_label">
                Need Help? Chat with us
              </div>
            </a>
          </li>

        </ul>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Header',

}
</script>
