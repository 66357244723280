<template>
    <div class="home">
        <section class="showcase-area" id="showcase">
            <div class="showcase-container">
                <h1 class="main-title" id="home">Your Problem, Our Solution</h1>
                <h4>We work with excellence to achieve assured results</h4>
            </div>
        </section>
    </div>
    <div class="submenu-container">
        <div class="submenu-navbar-container">

            <div class="submenu-navbar">
                <span @click="tab = 'journey'" class="submenu-btn btn-primary">Our
                        Journey</span>
                <span @click="tab = 'expertise'"
                        class="submenu-btn btn-primary">Our Expertise</span>
                <span @click="tab = 'services'"
                        class="submenu-btn btn-primary">Our Services</span>
            </div>

        </div>
        
        <div class="submenu-showcase-container">
            <component :is="tab" />
        </div>
    </div>
    
</template>

<script>
import journey from '../views/JourneyView.vue'
import expertise from '../views/ExpertiseView.vue'
import services from '../views/ServicesView.vue'

export default {
    name: 'Home',
    components: {
        journey,expertise, services
    },
    data() {
        return {
            tab : "journey"
        }
    }
}
</script>