<template>

  <div class="submenu-showcase">
    <img src="../assets/images/our_journey.png" />
  </div>

</template>

<script>
export default {
  name: 'JourneyView',
  components: {

  }
}
</script>