<template>
    <div class="footer">
        <div class="col-md-6">
            <div class="flex-row">
                <div class="bd-highlight">
                    <img src="../assets/images/digital_india.webp" alt="Digital India">
                </div>
                <div class="bd-highlight">
                    <img src="../assets/images/vocal_logo.webp" alt="Vocal for Local">
                </div>
                <div class="bd-highlight">
                    <img src="../assets/images/azadi.webp" alt="Azadi Ka Amrit Mahostav">
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <h4 class="footer-contact-heading">Contact Us</h4>
            <ul class="footer-contact">
                <li>
                    <div class="address-table">India:
                        <div class="address-table-cell">Address: 1002-1003, Jhelum Arorvansh Society, Plot-8, Sector-5, Dwarka, Delhi-110075
                        </div>
                        <div class="address-table-cell">Address: 33-B, Pocket-C, Phase-3, Ashok Vihar, Delhi-110052
                        </div>
                    </div>
                    <div class="address-table">United Kingdom:
                        <div class="address-table-cell">Address: 70 Darsley Gardens, Newcastle upon Tyne NE12 9RG
                        </div>
                        <div class="address-table-cell">Address: 2, Empire Court, Esplanade, Whitley Bay NE26 2AF
                        </div>
                    </div>
                    
                </li>
                <li><div class="address-table">India Contact No: <a href="tel:(+91-8826526412)"> +91-8826526412 </a>
                    </div>
                </li>
                <li><div class="address-table">UK Contact No: <a href="tel:(+44-7405251827)"> +44-7405251827 </a>
                    </div>
                </li>
                <li><div class="address-table">Email: <a href="mailto: contact@legalaura.com"> info@legalaura.com
                    </a></div></li>
            </ul>
        </div>
    </div>
</template>