<template>
  
  <div class="page">
  <Header />
  <Home />
  <WhyChooseUs />
  <Footer />
</div>
</template>

<script>
import Header from './components/Header.vue'
import Home from './components/Home.vue'
import Footer from './components/Footer.vue'
import WhyChooseUs from './components/WhyChooseUs'

export default {
  name: 'App',
  components: {
    Header,
    Home,
    WhyChooseUs,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
